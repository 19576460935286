<template>
  <div class="flex-box vertical content">
    <div class="data-title">
      <div class="rec-title-text">共{{ pagination.total }}条记录</div>
      <a-form-model class="query-form" layout="inline">
        <a-form-model-item label="操作者">
          <select-book type="user" v-model="form.wx_user_id" @change="updateList"></select-book>
        </a-form-model-item>
        <a-form-model-item label="操作时间">
          <a-range-picker
            v-model="form.create_time"
            :show-time="{ format: 'HH:mm' }"
            :placeholder="['开始时间', '结束时间']"
            format="YYYY-MM-DD HH:mm"
            value-format="YYYY-MM-DD HH:mm"
            @change="updateList"
            class="range-picker"
          />
        </a-form-model-item>
        <a-form-model-item label="操作类型">
          <a-select
            v-model="form.type"
            :options="operationOptions"
            placeholder="选择操作类型"
            allowClear
            style="width: 200px"
            @change="updateList"
          ></a-select>
        </a-form-model-item>
      </a-form-model>
      <a-space class="title-btn">
        <a-button @click="updateList">查询</a-button>
        <a-button type="primary" @click="exportData" :loading="exporting">
          {{ exporting ? "正在导出" : "数据导出" }}
        </a-button>
      </a-space>
    </div>
    <div class="data-area">
      <a-table
        ref="dataTable"
        :columns="columns"
        row-key="id"
        :data-source="list"
        :loading="loading"
        :pagination="pagination"
        :scroll="{ y: h }"
        @change="handleTableChange"
      >
      </a-table>
    </div>
  </div>
</template>

<script>
import { isEmpty, getKeyTitle } from "../../common/js/tool";
import { operationOptions, behaviorOptions, dealLogDetails } from "../../common/constant/safe";

export default {
  name: "SafeLogs",
  data() {
    return {
      h: 500,
      list: [],
      form: {},
      loading: false,
      columns: [
        {
          title: "操作时间",
          dataIndex: "create_time",
          customRender: (text) => {
            return <span>{new Date(text).pattern("yyyy-MM-dd HH:mm")}</span>;
          },
        },
        {
          title: "操作者",
          dataIndex: "wx_user_id",
          customRender: (text) => {
            return <open-data type="userName" openid={text} />;
          },
        },
        {
          title: "操作类型",
          dataIndex: "type",
          customRender: (text) => <span>{getKeyTitle(operationOptions, text, "value", "label")}</span>,
        },
        {
          title: "操作行为",
          dataIndex: "behavior",
          customRender: (text) => <span>{getKeyTitle(behaviorOptions, text, "value", "label")}</span>,
        },
        {
          title: "详细信息",
          // dataIndex: "details",
          customRender: dealLogDetails,
        },
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      searchKeyType: {
        create_time: function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&filter[${key}][gte]=${value[0]}:00&filter[${key}][lte]=${value[1]}:59`;
          }
          return res;
        },
      },
      operationOptions,
      exporting: false,
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getList();
    },
    updateList() {
      this.pagination.current = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      let url = `/admin/security-log?sort=-id`;
      let { current, pageSize } = this.pagination;
      url += `&page=${current}&pageSize=${pageSize}`;
      url += this.getQuery();
      this.$axios({ url, noTempleFilter: false })
        .then((res) => {
          this.loading = false;
          let list = res.data;
          this.list = list;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
    getQuery(keyType) {
      let query = "";
      let form = this.form;
      let searchKeyType = keyType || this.searchKeyType;
      for (let key in form) {
        if (!isEmpty(form[key])) {
          //模糊搜索
          let type = searchKeyType[key] || 0;
          if (typeof type == "function") {
            query += type(form, key);
          } else if (type == 1) {
            query += `&filter[${key}][like]=${form[key]}`;
          } else if (type == 2) {
            query += `&${key}=${form[key]}`;
          } else {
            query += `&filter[${key}]=${form[key]}`;
          }
        }
      }
      return query;
    },
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      let fileName = "日志数据";
      let searchKeyType = {
        wx_user_id: 2,
        create_time: function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&start_time=${value[0]}:00&end_time=${value[1]}:59`;
          }
          return res;
        },
        type: 2,
      };
      let url = "/admin/security-log/export?" + this.getQuery(searchKeyType);
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          this.exporting = false;
          const content = res;
          const blob = new Blob([content]);
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName + ".xlsx";
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        })
        .catch(() => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.ant-input-affix-wrapper {
  width: 180px;
}
.range-picker {
  text-align: left;
}
</style>
